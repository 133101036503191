<script>
import MenuForm from "@/views/menu/menu/menuForm";
import Component, {mixins} from "vue-class-component";

@Component({
    inject : {
        menuService:"menuService",
        visitorLevelService: "visitorLevelService",
        fileUploadService: "fileUploadService",
        toastService: "toastService",
    },
})

export default class MenuClone extends mixins(MenuForm){

  formId = "clone-menu-form"
  idToDuplicate = "";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }

  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.menuService.clone(this.idToDuplicate, this.model);
      this.$router.push("/menu/list");
      this.toastService.success(this.translations.success.menu_clone);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }


  getFormTitle() {
    return this.translations.pageTitles.menus_clone;
  }

  async afterCreate() {
    this.model = await this.menuService.read(this.$route.params.id);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.idToDuplicate = this.model.id;
    this.model.id = null;
    this.model.code = null;

    this.isReady = true;
  }

}

</script>