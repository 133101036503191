import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";
import WithVisitorLevelPermissions from "@/mixins/WithVisitorLevelPermissions";

@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService"
  }
})
export default class MenuForm extends mixins(EntityForm, WithVisitorLevelPermissions) {
  model = {};
  internal_events = [];

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "code",
            label: this.translations.labels.menu_form_code,
            type: "text",
            required: true,
            rules: "min:4"
          },
          {
            id: "idEvent",
            label: this.translations.labels.events,
            type: "select",
            items: this.events,
            required: true
          },
          {
            id: "priorityOrder",
            label: this.translations.labels.menu_form_priorityOrder,
            type: "number",
            rules: "gt:0|lte:255",
            required: true
          },
        ]
      },
      {
        id: "visitorLevels",
        type: "visitor-levels-permissions",
        resetColumns: true,
        transcludeModel: true,
        legend: this.translations.labels.visitor_level,
        label: this.translations.labels.visitor_level,
        levels: this.currentVisitorLevelsOptions
      }
    ];
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get languages() {
    if (
      this.model.idEvent === undefined ||
      this.model.idEvent === null ||
      this.events === undefined ||
      this.events === null
    )
      return [];
    const event = this.events.filter((e) => e.value == this.model.idEvent)[0];
    if (event != undefined) return event.languages;
    return [];
  }

  get helpLink() {
    return this.translations.pageHelp.menu;
  }

  async created() {
    this.internal_events = await this.eventService.list();
    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];

    this.afterCreate();
  }
}
